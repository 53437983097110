
import TabsComponent from "@/components/navigation/TabsComponent.vue"
import { reactive } from "vue"

export default {
  name: "HardwareSettings",
  components: { TabsComponent },
  setup() {
    const tabItemsHardware = reactive([
      { label: "Panels", routeName: "panelSettings" },
      { label: "Generators", routeName: "generatorSettings" },
      { label: "Measurers", routeName: "measurerSettings" },
    ])
    return {
      tabItemsHardware,
    }
  },
}
